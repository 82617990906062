import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { RestService } from '../services/rest.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-add-edit-strategy-call',
  templateUrl: './add-edit-strategy-call.component.html',
  styleUrl: './add-edit-strategy-call.component.scss'
})
export class AddEditStrategyCallComponent implements OnInit {
  strategyDetails: any;
  formGroup: FormGroup;
  call: any;
  isEdit?: boolean;
  accounts: any[];
  datesConfig: any;
  constructor(private toastr: NbToastrService, private rest: RestService, private formBuilder: FormBuilder, private dialogRef: NbDialogRef<AddEditStrategyCallComponent>) {
    this.formGroup = this.formBuilder.group({});
    this.accounts = [];
    this.fetchBrokerAccounts();
    this.datesConfig = {};
  }

  ngOnInit() {
    this.initializeForm()
  }

  private fetchBrokerAccounts() {
    const payload = {
      startRow: 0,
      endRow: 10,
      status: 'Active',
      order: [
        ['brokerIdentifier', 'ASC']
      ]
    }
    this.rest.getAccounts(payload).subscribe((response) => {
      this.accounts = (response.data?.rows || []);
    })
  }

  private initializeForm() {
    this.formGroup = this.formBuilder.group({
      accountId: new FormControl(null, [Validators.required])
    });
    (this.strategyDetails.metadata || []).forEach((control: any) => {
      const validations = control.validations
      const validators = [];
      if (validations.required) {
        validators.push(Validators.required);
      }
      if (validations.pattern) {
        validators.push(Validators.pattern(validations.pattern))
      }
      if (control.type === 'numeric' && validations.min) {
        validators.push(Validators.min(validations.min));
      }
      if (control.type === 'numeric' && validations.max) {
        validators.push(Validators.max(validations.max));
      }
      if (control.type === 'datepicker') {
        this.datesConfig[control.controlName] = { min: null, max: null };
      }
      this.formGroup.addControl(control.controlName, new FormControl(validations.defaultValue, validators), { emitEvent: false });
    });
    this.formGroup.get('indiceSelection')?.valueChanges.subscribe((value) => {
      if (value) {
        this.rest.getExpiryConfig(value).subscribe((response) => {
          this.datesConfig['runningDate'] = {
            min: DateTime.fromFormat(response.data.min, 'yyyy-MM-dd').toJSDate(),
            max: DateTime.fromFormat(response.data.max, 'yyyy-MM-dd').toJSDate()
          }
          this.formGroup.get('runningDate')?.patchValue(this.datesConfig['runningDate'].max)
        });
      }
    })
    this.patchValues();
  }

  private patchValues() {
    if (this.call) {
      const payload: any = {
        accountId: this.call.accountId
      };
      (this.strategyDetails.metadata || []).forEach((control: any) => {
        payload[control.controlName] = this.call.details[control.key];
        if (control.type == 'numeric' && control.validations.unit == '%') {
          payload[control.controlName] = payload[control.controlName] * 100;
        }
        if (control.type === 'datepicker') {
          this.datesConfig[control.controlName] = DateTime.fromFormat(this.call.details[control.key], 'yyyy-MM-dd').toJSDate();
        }
        if (this.isEdit && !control.validations.isModifiable) {
          this.formGroup.get(control.controlName)?.disable()
        }
      });
      this.formGroup.patchValue(payload);
      if (this.isEdit) {
        this.formGroup.get('accountId')?.disable();
      }
    }
  }

  closeDialog(data?: any) {
    this.dialogRef?.close(data);
  }

  onFormSubmit() {
    const formValues = this.formGroup.getRawValue();
    if (this.formGroup.valid) {
      const details: any = {};
      (this.strategyDetails.metadata || []).forEach((control: any) => {
        details[control.key] = formValues[control.controlName];
        if (control.type == 'numeric' && control.validations.unit == '%') {
          details[control.key] = Number((details[control.key] / 100).toFixed(2))
        }
        if (control.type == 'datepicker') {
          details[control.key] = DateTime.fromJSDate(details[control.key]).toFormat('yyyy-MM-dd');
        }
      })
      const payload = {
        callId: !this.isEdit ? null : this.call?.callId,
        strategyId: this.strategyDetails.strategyId,
        accountId: formValues.accountId,
        scripId: formValues.indiceSelection,
        details: details
      }
      if (!this.isEdit) {
        this.rest.submitCall(payload).subscribe((response) => {
          if (response.data?.callId) {
            const call = response.data;
            this.toastr.success(`Call Created Successfully for ${call.scripConfig.sym} ${DateTime.fromFormat(call.scripConfig.exp, 'yyyy-MM-dd').toFormat('dd MMM yy').toUpperCase()}`, 'Call Creation Success');
            this.closeDialog(response.data);
          } else {
            this.toastr.danger(response.message || `Call could not be created`, 'Call Creation Operation');
          }
        })
      } else {
        console.log(payload);
        this.rest.updateCall(payload).subscribe((response) => {
          if (response.data?.callId) {
            const call = response.data;
            this.toastr.success(`Call Updated Successfully for ${call.scripConfig.sym} ${DateTime.fromFormat(call.scripConfig.exp, 'yyyy-MM-dd').toFormat('dd MMM yy').toUpperCase()}`, 'Call Updation Success');
            this.closeDialog(response.data);
          } else {
            this.toastr.danger(response.message || `Call could not be updated`, 'Call Updation Operation');
          }
        });
      }
    }
  }

}
