import { Component } from '@angular/core';

import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NbIconLibraries, NbMenuItem, NbSidebarService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DhanFeedService } from './services/dhan.socket.service';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLoginPage: boolean;
  subscriptions: Subscription[];
  public menu: NbMenuItem[] = [
    // {
    //   title: 'Dashboard',
    //   icon: 'dashboard',
    //   link: RouteConstants.LINK_DASHBOARD,
    //   home: true,
    // },
    {
      title: 'Corporate Announcements',
      icon: 'news',
      link: 'ai-articles', pathMatch: 'full', home: true

    }
  ];

  constructor(private storage: StorageService, private sidebarService: NbSidebarService, private iconLibraries: NbIconLibraries, private router: Router, private dhanFeed: DhanFeedService) {
    this.isLoginPage = false;
    this.subscriptions = [];
    this.setIconLibrary();
    this.setListeners();
    // setTimeout(() => this.dhanFeed.connect('wss://api-feed.dhan.co'), 3000);




  }

  private setIconLibrary() {
    this.iconLibraries.registerFontPack('tabler-icons', { iconClassPrefix: 'ti', packClass: 'ti' });
    this.iconLibraries.setDefaultPack('tabler-icons');
  }

  private setListeners() {
    this.subscriptions.push(this.router.events.pipe(
      filter((e: any): e is RouterEvent => e instanceof NavigationEnd)
    ).subscribe((e: any) => {
      this.isLoginPage = e.urlAfterRedirects.indexOf('/auth') > -1;
    }));

    this.subscriptions.push(this.storage.watch('entitlements').subscribe((data) => {

    }));

    this.subscriptions.push(this.storage.watch('user').subscribe((data) => {

    }));

    this.subscriptions.push(this.sidebarService.onToggle().subscribe((value) => {
      window.dispatchEvent(new Event('resize'));
    }))
  }

  ngOnDestory() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    })
  }
}

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrl: './app.component.scss'
// })
// export class AppComponent implements OnDestroy {
//   formGroup: FormGroup;
//   indicesList: Array<any>;
//   accounts: any[];
//   rankingPredicateList: Array<any>;
//   optionsType: string[];
//   formChangesSubscriptions: Array<Subscription | undefined>;
//   config: any;
//   constructor(private formBuilder: FormBuilder, private rest: RestService, private dhanFeed: DhanFeedService) {
//     this.accounts = [];
//     this.formChangesSubscriptions = [];
//     this.optionsType = ['ALL', 'CALL', 'PUT']
//     this.formGroup = formBuilder.group({});
//     this.indicesList = AppConstant.INDEX_LIST;
//     this.rankingPredicateList = AppConstant.RANKING_PREDICATE_LIST;
//     this.config = { min: DateTime.now().toJSDate() }
//     if (DateTime.now().weekday > 5) {
//       this.config.min = DateTime.now().plus({ day: DateTime.now().weekday == 6 ? 2 : 1 }).toJSDate();
//     }
//     if (DateTime.fromJSDate(this.config.min).startOf('day').equals(DateTime.now().startOf('day'))) {
//       this.config.min = (DateTime.now().hour <= 15 ? DateTime.fromJSDate(this.config.min) : DateTime.now().plus({ day: 1 })).toJSDate();
//     }
//     this.initForm();
//     this.fetchBrokerAccounts();
//     setTimeout(() => {
//       dhanFeed.connect('wss://api-feed.dhan.co')
//     }, 10000);
//   }

//   ngOnDestroy(): void {
//     this.formChangesSubscriptions.forEach((subscription: Subscription | undefined) => {
//       subscription?.unsubscribe();
//     });
//   }

//   onFormSubmit() {
//     if (this.formGroup.valid) {
//       const formValues = this.formGroup.getRawValue();
//       const selectedIndex = this.indicesList.find((indice) => indice.IndexDesc === formValues.indice);
//       const selectedRanking = this.rankingPredicateList.find((ranking) => ranking.label === formValues.ranking);
//       const payload = {
//         accountId: formValues.accountId,
//         indice: `${selectedIndex?.SegmentId}@${selectedIndex?.Token}`,
//         ranking: selectedRanking.value || this.rankingPredicateList[0].value,
//         exposure: Number(formValues.exposure),
//         stoploss: Number(formValues.stoploss),
//         optionType: formValues.optionType,
//         dayHighPer: formValues.dayHighConditionCheck ? formValues.dayHighCondition : 0,
//         dayLowPer: formValues.dayLowConditionCheck ? formValues.dayLowCondition : 0,
//         profitPer: 300,
//         startDateTime: formValues.startDateTime
//       }
//       this.rest.submitCall(payload).subscribe((response) => {
//         if (response.data) {
//           alert({ title: 'Call submitted successfully', text: `Call has been submitted succesfully for ${response.data.expiry}`, delay: 3000 });
//           this.resetForm();
//         }
//       }, (error) => {
//         alert({ text: 'Something went wrong', title: 'Oops!!', delay: 3000, type: 'error' });
//       })
//     } else {
//       alert({ type: 'error', text: 'Please Fill all required fields.', delay: 3000 });
//     }
//   }

//   resetForm() {
//     this.formGroup.reset();
//     this.formGroup.markAsPristine();
//     this.formGroup.patchValue({ accountId: '', optionType: 'ALL', ranking: this.rankingPredicateList[0].label, dayHighConditionCheck: true, dayLowConditionCheck: true, dayHighCondition: 1, dayLowCondition: 1, exposure: 10000, stoploss: 10, startDateTime: this.config.min });
//   }

//   onIndiceSelect(event: TypeaheadMatch<any>) {
//     if (!event.item?.contract) {
//       this.rest.getContracts(`${event.item.SegmentId}@${event.item.Token}`).subscribe((response) => {
//         event.item.contract = response.data;
//         this.config.max = DateTime.fromFormat(response.data.expiry, 'yyyy-MM-dd').toJSDate();
//       })
//     } else {
//       this.config.max = DateTime.fromFormat(event.item.contract.expiry, 'yyyy-MM-dd').toJSDate();
//     }
//   }

//   private initForm() {
//     this.formGroup = this.formBuilder.group({
//       accountId: new FormControl('', [Validators.required]),
//       indice: new FormControl('', [Validators.required]),
//       optionType: new FormControl('ALL', [Validators.required]),
//       ranking: new FormControl(this.rankingPredicateList[0].label, [Validators.required]),
//       exposure: new FormControl(10000, [Validators.required, Validators.min(1000)]),
//       dayHighCondition: new FormControl(1, [Validators.required, Validators.min(1)]),
//       dayLowCondition: new FormControl(1, [Validators.required, Validators.min(1)]),
//       dayHighConditionCheck: new FormControl(true),
//       dayLowConditionCheck: new FormControl(true),
//       startDateTime: new FormControl(this.config.min, [Validators.required]),
//       stoploss: new FormControl(10, [Validators.required, Validators.min(10), Validators.max(50)])
//     });
//     this.subscribeFormChanges();
//   }

//   private subscribeFormChanges() {
//     this.formChangesSubscriptions.push(this.formGroup.get('dayHighConditionCheck')?.valueChanges.subscribe((value) => {
//       const control = this.formGroup.get('dayHighCondition');
//       this.toggleControlMeta(control, value);
//     }));

//     this.formChangesSubscriptions.push(this.formGroup.get('dayLowConditionCheck')?.valueChanges.subscribe((value) => {
//       const control = this.formGroup.get('dayLowCondition');
//       this.toggleControlMeta(control, value);
//     }));
//   }

//   private toggleControlMeta(control: AbstractControl | null, value: boolean) {
//     if (control) {
//       if (value) {
//         control.addValidators([Validators.required]);
//         control.enable();
//         control.patchValue(1);
//       } else {
//         control.removeValidators([Validators.required]);
//         control.disable();
//         control.reset();
//       }
//     }
//   }

//   private fetchBrokerAccounts() {
//     this.rest.getAccounts().subscribe((response) => {
//       this.accounts = response.data || [];
//     })
//   }
// }
