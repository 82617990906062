<div class="d-flex justify-content-between align-items-center">
  <h6 class="h6">{{strategyDetails?.strategyName}}</h6>
  <div class="d-flex justify-content-end mb-2">
    <button nbButton size="medium" nbTooltip="Apply Filters" [nbPopover]="filtersPopOver" nbPopoverTrigger="noop"
      nbPopoverPlacement="left" ghost class="me-2" (click)="openFiltersSection()"><nb-icon size="medium" icon="filter"
        status="primary"></nb-icon></button>
    <button nbButton size="small" status="info" class="me-2" (click)="applyFilters()">Refresh</button>
    <button nbButton size="small" status="primary" (click)="openCreateDialog(null)">Create</button>
  </div>
</div>
<nb-card *ngFor="let item of calls" size="small" [class.simulation]="item.details.isSimulation">
  <nb-card-header>
    <div class="d-flex justify-content-between align-items-center">
      <label class="label">{{item.title}} {{item.clientId}}({{item.brokerCode}})</label>
      <div class="d-flex align-items-center justify-content-end">

        <button nbButton (click)="showCallInfo(item) " size="medium" nbTooltip="Show Info" ghost><nb-icon size="medium"
            icon="info-circle"></nb-icon></button>

        <button nbButton (click)="cloneCall(item) " size="medium" nbTooltip="Clone Call" ghost><nb-icon size="medium"
            icon="copy"></nb-icon></button>

        <button nbButton (click)="updateRunningStatus(item, 'Pause')"
          *ngIf="(['OrderPlaced', 'Active'].includes(item.status) && item.runningStatus=='Tracking')" size="medium"
          nbTooltip="Pause" ghost><nb-icon size="medium" icon="player-pause"></nb-icon></button>

        <button nbButton (click)="updateRunningStatus(item, 'Play')"
          *ngIf="(['OrderPlaced', 'Active'].includes(item.status) && item.runningStatus=='Paused')" size="medium"
          nbTooltip="Play" status="success" ghost><nb-icon size="medium" icon="player-play"></nb-icon></button>

        <button nbButton [nbPopover]="restartConfirmation" nbPopoverTrigger="click" nbPopoverPlacement="bottom"
          *ngIf="(['OrderPlaced', 'Active'].includes(item.status) && item.runningStatus=='Tracking')" size="medium"
          nbTooltip="Restart" (click)="callForOperation(item)" status="warning" ghost><nb-icon size="medium"
            icon="refresh"></nb-icon></button>

        <button nbButton *ngIf="item.status=='Pending'" size="medium" nbTooltip="Edit" ghost
          (click)="openCreateDialog(item, true)"><nb-icon size="medium" icon="pencil"></nb-icon></button>

        <button nbButton [nbPopover]="deleteConfirmation" nbPopoverTrigger="click" nbPopoverPlacement="bottom"
          (click)="callForOperation(item)" *ngIf="item.status=='Pending'" size="medium" status="danger"
          nbTooltip="Delete" ghost><nb-icon size="medium" icon="trash"></nb-icon></button>

        <button nbButton [nbPopover]="exitConfirmation" nbPopoverTrigger="click" nbPopoverPlacement="bottom"
          *ngIf="(['OrderPlaced', 'Active'].includes(item.status) && item.runningStatus=='Tracking')"
          (click)="callForOperation(item)" size="medium" status="danger" nbTooltip="Exit & Stop" ghost><nb-icon
            size="medium" icon="logout"></nb-icon></button>
      </div>
    </div>

  </nb-card-header>
  <nb-card-body class="py-0">
    <div class="d-flex justify-content-start my-2" *ngIf="item.status!='Pending'">
      <label class="label">Buy v/s Sell: <span class="text-success">{{item.buyCount}}</span>/<span
          class="text-danger">{{item.sellCount}}</span> |</label>
      <label class="label ms-2">Cycles Pending: {{item.iterationsPending}}</label>
    </div>
    <ag-grid-angular class="ag-theme-balham-dark" [id]="item.callId" [rowData]="item.orders||[]"
      [columnDefs]="orderEntryColumnDefs">
    </ag-grid-angular>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex justify-content-between align-items-center">
      <label class="label">
        <span *ngIf="item.status=='Pending'"> Scheduled</span>
        <span *ngIf="item.status=='Active'"> Running</span>
        <span *ngIf="item.status=='Expired' || item.status=='Completed'"> Ran</span>

        on {{item.startDateTime|date:'dd MMM yy'}}
        from {{item.startDateTime|date:'hh:mm a'}} to {{item.endDateTime|date:'hh:mm a'}}</label>
      <div class="d-flex justify-content-end align-items-center">
        <label class="label me-2">Current Status: </label>
        <span class="label" *ngIf="item.status=='Pending'"> Scheduled</span>
        <span class="label text-danger" *ngIf="item.status=='Deleted'"> Deleted</span>
        <span class="label" *ngIf="item.status=='Expired'"> Expired</span>
        <span class="label" *ngIf="item.status=='Active' || item.status=='OrderPlaced'">
          <span class="label text-warning" *ngIf="item.runningStatus=='Paused'">Paused</span>
          <span class="label text-success" *ngIf="item.runningStatus=='Completed'">Completed</span>
          <span class="label text-danger" *ngIf="item.runningStatus=='ManualExited'">Exitted Manually</span>
          <span class="label text-primary" *ngIf="item.runningStatus=='Tracking'">Tracking</span>
        </span>
        <span class="label" *ngIf="item.status=='Completed'">
          <span class="label text-danger" *ngIf="item.runningStatus=='ManualExited'">Exitted Manually</span>
          <span class="label text-success" *ngIf="item.runningStatus=='Completed'">Completed</span>
        </span>
      </div>
    </div>

  </nb-card-footer>
</nb-card>
<nb-card *ngIf="!calls?.length && !isLoading">
  <nb-card-body>
    <div class="flex-column align-content-center align-items-center justify-content-center">
      <nb-icon icon="chart-bar-off" size="giant"></nb-icon>
      <h6> No Calls for {{strategyDetails?.strategyName}}</h6>
    </div>
  </nb-card-body>
</nb-card>
<nb-card *ngIf="isLoading">
  <nb-card-body>
    <div class="flex-column align-content-center align-items-center justify-content-center">
      <nb-icon icon="chart-bar-off" size="giant"></nb-icon>
      <h6> Loading Calls</h6>
    </div>
  </nb-card-body>
</nb-card>
<ng-template #deleteConfirmation>
  <nb-card-body class="p-2">
    <div class="flex-column">
      <label class="label mb-2">Are you sure you want to delete the call?</label>
      <div class="d-flex justify-content-end">
        <button nbButton status="danger" size="small" class="me-2" (click)="deleteCall()">Yes</button>
        <button nbButton size="small">Cancel</button>
      </div>
    </div>
  </nb-card-body>
</ng-template>

<ng-template #restartConfirmation>
  <nb-card-body class="p-2">
    <div class="flex-column">
      <label class="label mb-2">Are you sure you want to restart the Call?</label>
      <div class="d-flex justify-content-end">
        <button nbButton size="small" status="danger" class="me-2"
          (click)="updateRunningStatus(selectedCall, 'Restart')">Yes</button>
        <button nbButton size="small">Cancel</button>
      </div>
    </div>
  </nb-card-body>
</ng-template>

<ng-template #exitConfirmation>
  <nb-card-body class="p-2">
    <div class="flex-column">
      <label class="label mb-2">Are you sure you want to Exit and Stop the Call?</label>
      <div class="d-flex justify-content-end">
        <button nbButton size="small" status="danger" class="me-2"
          (click)="updateRunningStatus(selectedCall, 'Exit')">Yes</button>
        <button nbButton size="small">Cancel</button>
      </div>
    </div>
  </nb-card-body>
</ng-template>

<ng-template #infoDialog let-data let-ref="infoDialogRef">
  <nb-card>
    <nb-card-header>Strategy Call Information</nb-card-header>
    <nb-card-body>
      <table class="table table-bordered">
        <tbody>
          <tr *ngFor="let item of data">
            <th>{{item.label}}</th>
            <td>
              <nb-icon nbSuffix icon="currency-rupee" size="medium" *ngIf="item.unit=='INR'"></nb-icon>
              {{item.value}}
              <nb-icon nbSuffix icon="percentage" size="medium" *ngIf="item.unit=='%'"></nb-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" (click)="infoDialogRef?.close()" size="small">Close Dialog</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #filtersPopOver>
  <nb-card class="m-0 filter-card">
    <nb-card-header>Apply Filters</nb-card-header>
    <nb-card-body class="p-0">
      <div class="p-3 filter-section" *ngFor="let item of filters" [formGroup]="filtersForm">
        <label class="label mb-2">{{item.label}}</label>
        <nb-select fullWidth multiple *ngIf="item.type=='multiselect'" #multiSelect [formControlName]="item.key"
          placeholder="Select {{item.label }}" size="small">
          <nb-select-label>{{getMultiselectLabel(item.key)}}</nb-select-label>
          <nb-option *ngFor="let option of item.source" [value]="option.value">{{option.label}}</nb-option>
        </nb-select>
        <nb-form-field *ngIf="item.type=='daterange' && item.key==='runningDateRange'">
          <input nbInput placeholder="Pick Date Range" [nbDatepicker]="runningPicker" fieldSize="small"
            [formControlName]="item.key">
          <nb-rangepicker #runningPicker ></nb-rangepicker>
        </nb-form-field>
        <nb-form-field *ngIf="item.type=='daterange' && item.key==='createdDateRange'">
          <input nbInput placeholder="Pick Date Range" [nbDatepicker]="createdPicker" fieldSize="small"
            [formControlName]="item.key">
          <nb-rangepicker #createdPicker ></nb-rangepicker>
        </nb-form-field>
      </div>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="primary" (click)="applyFilters()" size="small">Apply</button>
      <button nbButton status="info" (click)="closeFiltersSection()" size="small">Close</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
