import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NbLayoutModule, NbMenuModule, NbUserModule, NbActionsModule, NbSidebarModule, NbContextMenuModule, NbButtonModule, NbIconModule, NbSelectModule, NbThemeModule, NbToastrModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule.forRoot(),
  NbUserModule,
  NbActionsModule,
  NbSidebarModule.forRoot(),
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbThemeModule,
  NbContextMenuModule,
  NbToastrModule.forRoot()
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent
];
@NgModule({
  imports: [CommonModule, ...NB_MODULES],
  exports: [
    HeaderComponent,
    FooterComponent
  ],
  declarations: [
    HeaderComponent,
    FooterComponent
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NbThemeModule.forRoot({
          name: 'dark'
        }, [CORPORATE_THEME, DARK_THEME]).providers || [],
      ],
    };
  }
}
