<div class="d-flex justify-content-start align-items-center flex-wrap">
  <nb-card class="me-2 flex-grow-1" *ngFor="let item of strategies">
    <nb-card-header>
      <div class="d-flex align-items-center justify-content-between">
        <div class="card-row d-flex justify-content-start align-items-center">
          <nb-icon icon="bulb" status="danger" class="me-2"></nb-icon>
          <h6 class="h6">{{item.strategyName}}</h6>
        </div>
        <button nbButton ghost size="medium" (click)="navigateToCallSection(item)"><nb-icon size="medium"
            icon="chevron-right"></nb-icon></button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="call-stats flex-column flex-wrap">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div class="col-6 d-flex justify-content-start align-items-center">
            <h1 class="m-0 me-1">{{(item.scheduledCalls?.length||0)|number:'2.00'}}</h1>
            <label> Calls Scheduled Today</label>
          </div>
          <div class="col-6 d-flex justify-content-start align-items-center">
            <h1 class="m-0 me-1">{{(item.createdCalls?.length||0)|number:'2.00'}}</h1>
            <label> Calls Created Today</label>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="col-6 d-flex justify-content-start align-items-center">
            <h1 class="m-0 me-1">{{(item.ranCalls?.length||0)|number:'2.00'}}</h1>
            <label> Calls Ran Today</label>
          </div>
          <div class="col-6 d-flex justify-content-start align-items-center">
            <h1 class="m-0 me-1">{{(item.runningCalls?.length||0)|number:'2.00'}}</h1>
            <label> Calls Running Now</label>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</div>
