import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { RestService } from './rest.service';
import { StorageService } from './storage.service';
import { RouteConstants } from '../constants/constant';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private storage: StorageService, private router: Router, private rest: RestService) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let token = this.storage.getToken();
    let userData = this.storage.getUserData();
    let isValidUser = this.storage.isValidUser();
    if (token && userData && isValidUser && route.url[0].path == RouteConstants.AUTH_MAIN_ROUTE) {
      return false;
    } if ((!token || !userData || !isValidUser) && route.url[0].path == RouteConstants.AUTH_MAIN_ROUTE) {
      return true;
    } else if (token && userData && route.url[0].path != RouteConstants.AUTH_MAIN_ROUTE) {
      if (!isValidUser) {
        this.router.navigate([RouteConstants.AUTH_LOGIN_ROUTE]);
        return (false);
      }
      return true;
    } else {
      this.router.navigate([RouteConstants.AUTH_LOGIN_ROUTE]);
      return (false);
    }
  }

}
