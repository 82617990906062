<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle" *ngIf="!isLoginPage">
      <nb-icon icon="menu-2" pack="tabler-icons"></nb-icon>
    </a>
    <!-- <a class="logo d-flex align-items-center" href="#" (click)="navigateHome()"><img
        class="mr-2 img img-responsive me-2" src="../../../../assets/logo.png"> OI Strategy
      <span>&nbsp;Console</span></a> -->

    <a class="logo d-flex align-items-center" href="#" (click)="navigateHome()"><img
        class="mr-2 img img-responsive me-2" src="../../../../assets/logo.png"> Stock Bee</a>
  </div>
</div>

<div class="header-container" *ngIf="!isLoginPage">
  <nb-actions size="small">
    <nb-action class="user-action">
      <nb-user [nbContextMenu]="userMenu" nbContextMenuTag="profile-options" [onlyPicture]="userPictureOnly"
        [name]="user?.mobile" [picture]="user?.avator" [title]="user?.role?.role |titlecase">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
