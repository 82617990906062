<!-- <div class="d-flex flex-column">
  <nav class="navbar bg-primary sticky-top navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Algo Trading</a>
    </div>
  </nav>
  <div class="m-2 card flex-grow-1">
    <h6 class="card-header">Algo Trade Submission</h6>

    <div class="card-body">
      <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()">
        <div class="d-flex justify-content-evenly">
          <div class="col-8">
            <div class="my-2 d-flex justify-content-start align-items-start flex-row flex-nowrap">
              <div class="form-group me-3">
                <label for="staticEmail" class="form-label">Account</label>
                <div>
                  <select class="form-select" formControlName="accountId">
                    <option value="">Select Account</option>
                    <option *ngFor="let item of accounts" [value]="item.id">{{item.clientId}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group ms-3 me-3">
                <label for="staticEmail" class="form-label">Indice</label>
                <div>
                  <input formControlName="indice" [typeahead]="indicesList" [typeaheadOptionsLimit]="7"
                    [typeaheadMinLength]="0" placeholder="Select Stock/Indice" class="form-control"
                    typeaheadOptionField="IndexDesc" typeaheadGroupField="ExchangeName"
                    (typeaheadOnSelect)="onIndiceSelect($event)">
                </div>
              </div>
              <div class="form-group ms-3 me-3">
                <label for="staticEmail" class="form-label">Contract By</label>
                <div>
                  <input formControlName="ranking" [typeahead]="rankingPredicateList" [typeaheadOptionsLimit]="7"
                    [typeaheadMinLength]="0" placeholder="Select Contract By" class="form-control"
                    typeaheadOptionField="label">
                </div>
              </div>
            </div>
            <div class="my-2 d-flex justify-content-start align-items-start flex-row flex-nowrap">
              <div class="form-group me-3">
                <label for="staticEmail" class="form-label">Start Date</label>
                <div>
                  <input type="text" bsDatepicker formControlName="startDateTime" class="form-control"
                    [minDate]="config.min" [maxDate]="config.max" [bsConfig]="{ dateInputFormat: 'DD-MMM-YYYY' }"
                    [daysDisabled]="[6,0]">
                </div>
              </div>
              <div class=" form-group ms-3 me-3">
                <label for="staticEmail" class="form-label">Exposure</label>
                <div class="input-group mb-3">
                  <span class="input-group-text">&#8377;</span>
                  <input type="number" formControlName="exposure" class="form-control" step="1">
                </div>
              </div>
              <div class="form-group ms-3 me-3">
                <label for="staticEmail" class="form-label">StopLoss</label>
                <div class="input-group mb-3">
                  <input type="number" formControlName="stoploss" class="form-control" step="1">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
            <div class="my-2 d-flex justify-content-start align-items-start flex-row flex-nowrap">
              <table class="table table-sm table-bordered me-3"
                [class.ng-invalid]="!formGroup.get('dayLowConditionCheck')?.value && !formGroup.get('dayHighConditionCheck')?.value">
                <thead>
                  <tr>
                    <th colspan="3">Entry Conditions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input class="form-check-input" formControlName="dayHighConditionCheck" type="checkbox"
                        id="flexCheckDefault" /> Day High is
                    </td>
                    <td>
                      <div class="input-group">
                        <input class="form-control" formControlName="dayHighCondition" type="number"
                          id="flexCheckDefault" /><span class="input-group-text">%</span>
                      </div>
                    </td>
                    <td>
                      <div class="input-group">
                        of CMP
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input class="form-check-input" formControlName="dayLowConditionCheck" type="checkbox"
                        id="flexCheckDefault" /> CMP is
                    </td>
                    <td>
                      <div class="input-group">
                        <input class="form-control col-2" formControlName="dayLowCondition" type="number"
                          id="flexCheckDefault" /><span class="input-group-text">%</span>
                      </div>
                    </td>
                    <td>
                      <div class="input-group">
                        of Day Low
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-start align-items-start flex-row flex-nowrap">
              <div class="form-group">
                <button type="submit" class="btn btn-primary btn-sm me-3" [disabled]="formGroup.invalid">Validate &
                  Submit</button>
                <button type="button" class="btn btn-danger btn-sm" (click)="resetForm()">Clear</button>
              </div>
            </div>
          </div>
          <div class="col-3">
            <p><strong>Note: </strong><br>
              All the orders would be <strong>Intraday</strong> in nature.
              At any given point, only one order can be live. Next Conditions would only be checked,
              If the order has exited at SL. Indice selected would be the option contract expiring on that particular
              day</p>

            <small class="text-danger">
              <ul class="list-group">
                <li class="list-group-item list-group-item-danger"
                  *ngIf="formGroup.get('accountId')?.dirty && formGroup.get('accountId')?.invalid">Account field is
                  required</li>
                <li class="list-group-item list-group-item-danger"
                  *ngIf="formGroup.get('indice')?.dirty && formGroup.get('indice')?.invalid">Indice is mandatory
                  field.</li>
                <li class="list-group-item list-group-item-danger"
                  *ngIf="formGroup.get('optionType')?.dirty && formGroup.get('startDateTime')?.invalid">Start Date to
                  be selected</li>
                <li class="list-group-item list-group-item-danger"
                  *ngIf="!formGroup.get('dayLowConditionCheck')?.value && !formGroup.get('dayHighConditionCheck')?.value">
                  One of the Entry Conditions to be filled
                </li>
                <li class="list-group-item list-group-item-danger"
                  *ngIf="formGroup.get('dayHighConditionCheck')?.value && formGroup.get('dayHighCondition')?.dirty && formGroup.get('dayHighCondition')?.invalid">
                  Day High Condition to be filled with minimum 1%
                </li>
                <li class="list-group-item list-group-item-danger"
                  *ngIf="formGroup.get('dayLowConditionCheck')?.value && formGroup.get('dayLowCondition')?.dirty && formGroup.get('dayLowCondition')?.invalid">
                  Day Low Condition to be filled with minimum 1%
                </li>
                <li class="list-group-item list-group-item-danger"
                  *ngIf="formGroup.get('exposure')?.dirty && formGroup.get('exposure')?.invalid">
                  Exposure to be filled with minimum &#8377;1,000
                </li>
                <li class="list-group-item list-group-item-danger"
                  *ngIf="formGroup.get('stoploss')?.dirty && formGroup.get('stoploss')?.invalid">
                  Stoploss to be filled between 10%-50%
                </li>
              </ul>
            </small>
          </div>
        </div>
      </form>
    </div>
  </div>
</div> -->

<nb-layout windowMode>
  <nb-layout-header fixed>
    <ngx-header></ngx-header>
  </nb-layout-header>

  <nb-sidebar class="menu-sidebar" *ngIf="!isLoginPage" tag="menu-sidebar" responsive>
    <nb-menu [items]="menu"></nb-menu>
  </nb-sidebar>

  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>

  <nb-layout-footer fixed>
    <ngx-footer></ngx-footer>
  </nb-layout-footer>
</nb-layout>
