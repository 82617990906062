import { Component } from '@angular/core';
import { RestService } from '../services/rest.service';
import { DateTime } from 'luxon';
import { startOf } from 'ngx-bootstrap/chronos';
import { Router } from '@angular/router';
import { RouteConstants } from '../constants/constant';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  strategies: any[];
  constructor(private rest: RestService, private router: Router) {
    this.strategies = [];
    this.fetchStrategies();
  }
  fetchStrategies() {
    const payload = {
      startRow: 0,
      endRow: 10,
      status: ['Published']
    }
    this.rest.fetchStrategies(payload).subscribe((response) => {
      this.strategies = response.data.rows;
      this.fetchTodaysCalls();
      this.fetchTodaysCreatedCalls();
    })
  }

  fetchTodaysCalls() {
    const payload = {
      "startRow": 0,
      "endRow": 100,
      "runningDateRange": [
        DateTime.now().startOf('day'), DateTime.now().endOf('day')
      ],
      "order": [
        ["startDateTime", "DESC"]
      ]
    }
    this.rest.fetchStrategyCalls(payload).subscribe((response) => {
      this.strategies.forEach((strategy) => {
        strategy.scheduledCalls = [...strategy.scheduledCalls || [], ...response.data.rows.filter((call: any) => call.strategyId === strategy.strategyId && call.status === 'Pending')];
        strategy.runningCalls = [...strategy.runningCalls || [], ...response.data.rows.filter((call: any) => call.strategyId === strategy.strategyId && ['OrderPlaced', 'Active'].includes(call.status))];
        strategy.ranCalls = [...strategy.ranCalls || [], ...response.data.rows.filter((call: any) => call.strategyId === strategy.strategyId && ['Completed'].includes(call.status) && ['Completed', 'ManulExited'].includes(call.runningStatus))]
      });
    });
  }

  fetchTodaysCreatedCalls() {
    const payload = {
      "startRow": 0,
      "endRow": 100,
      status: ['Active', 'Pending', 'Expired', 'Completed', 'OrderPlaced'],
      "createdDateRange": [
        DateTime.now().startOf('day'), DateTime.now().endOf('day')
      ],
      "order": [
        ["startDateTime", "DESC"]
      ]
    }
    this.rest.fetchStrategyCalls(payload).subscribe((response) => {
      this.strategies.forEach((strategy) => {
        strategy.createdCalls = response.data.rows.filter((call: any) => call.strategyId === strategy.strategyId)
      });
    });
  }

  navigateToCallSection(item: any) {
    this.router.navigate([RouteConstants.LINK_STRATEGY_DETAIL + '/' + item.strategyId]);
  }
}
