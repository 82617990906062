<nb-card>
  <nb-card-header>
    <div class="d-flex justify-content-between align-items-center">
      <h6>{{isEdit?'Update':'Create'}} {{strategyDetails?.strategyName}}</h6>
      <button (click)="closeDialog()" size="small" nbButton type="button" ghost>
        <nb-icon icon="close-outline" pack="nebular-essentials"></nb-icon>
      </button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="form" [formGroup]="formGroup">
      <div class="form-group mb-2">
        <label for="account" class="label">Select Account <nb-icon pack="tabler-icons" class="ms-1 mb-1" icon="info-circle" size="medium" nbTooltipStatus="primary" nbTooltip="Account in which algorith will place orders"></nb-icon></label>

        <nb-form-field size="small">
          <nb-select placeholder="Select Account" fullWidth formControlName="accountId">
            <nb-option [value]="option.accountId"
              *ngFor="let option of accounts">{{option.clientId}}({{option.broker}})</nb-option>
          </nb-select>
        </nb-form-field>
      </div>
      <div class="form-group mb-2" *ngFor="let item of (strategyDetails?.metadata||[])">
        <label for="{{item.key}}" class="label mb-1">{{item.label}} <nb-icon pack="tabler-icons" class="ms-1 mb-1" icon="info-circle" size="medium" *ngIf="item.type!=='toggle' && item?.validations?.description" [nbTooltip]="item?.validations?.description" nbTooltipStatus="primary"></nb-icon></label>
        <nb-form-field *ngIf="item.type=='numeric'" size="small">
          <nb-icon nbSuffix icon="percentage" size="medium" *ngIf="item.validations?.unit=='%'"></nb-icon>
          <nb-icon nbSuffix icon="currency-rupee" size="medium" *ngIf="item.validations?.unit=='INR'"></nb-icon>
          <input [id]="item.key" nbInput size="small" [formControlName]="item.controlName" type="number"
            [attr.min]="item.validations?.min||null" [attr.max]="item.validations?.max||null"
            [step]="item.validations?.step||1" fullWidth />
        </nb-form-field>
        <nb-form-field *ngIf="item.type=='select'" size="small">
          <nb-select placeholder="{{item.label}}" fullWidth [formControlName]="item.controlName">
            <nb-option [value]="option.value" *ngFor="let option of item.source?.options">{{option.label}}</nb-option>
          </nb-select>
        </nb-form-field>
        <nb-form-field *ngIf="item.type=='datepicker'" size="small">
          <nb-icon nbSuffix icon="calendar-event" size="medium"></nb-icon>
          <input nbInput placeholder="Pick Date" [nbDatepicker]="picker" fullWidth [formControlName]="item.controlName">
          <nb-datepicker #picker [min]="datesConfig[item.controlName]?.min"
            [max]="datesConfig[item.controlName]?.max"></nb-datepicker>
        </nb-form-field>
        <div *ngIf="item.type=='toggle'" class="d-flex justify-content-between align-items-center">
          <nb-toggle [formControlName]="item.controlName"></nb-toggle>
          <label class="m-0 ms-3 text-start">
            <small><small>{{item.validations?.description}}</small></small>
          </label>
        </div>

      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex justify-content-end align-items-center">
      <button type="button" nbButton size="small" status="primary" (click)="onFormSubmit()"
        [disabled]="formGroup.invalid">Submit</button>
    </div>
  </nb-card-footer>
</nb-card>
