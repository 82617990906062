<div class="d-flex justify-content-between align-items-center">
  <h6 class="h6">AI Summary Generated Articles</h6>
  <div class="d-flex justify-content-end mb-2">
    <button nbButton size="small" status="info" class="me-2" (click)="refresh()">Refresh</button>
  </div>
</div>
<nb-list nbInfiniteList listenWindowScroll [threshold]="500" (bottomThreshold)="loadNext()">
  <nb-list-item *ngFor="let item of articles;let i=index">
    <nb-card class="col-12 p-0">
      <nb-card-header class="d-flex justify-content-between  align-items-center">
        <label class="label col-8 p-0">{{(i+1)}}.{{item.subject}}</label>
        <div class="d-flex justify-content-end align-items-center">
          <label class="label me-2"><span class="text-primary">{{item.category}}</span> / <span
              class="text-secondary">{{item.subCategory}}</span></label>
          <button nbButton (click)="downloadAttachment(item.attachment) " size="medium" nbTooltip="Open File"
            ghost><nb-icon size="medium" icon="external-link"></nb-icon></button>
        </div>
      </nb-card-header>
      <nb-card-body>
        <div class="d-flex justify-content-between align-items-start">
          <div class="d-flex flex-column col-6 p-0 pe-2 bse-summary">
            <label class="label"><nb-icon icon="notes" size="small"></nb-icon> BSE
              Summary/Description</label>
            <p class="mt-1 mb-0 title text-align-justify"><small>{{item.description}}</small></p>
          </div>
          <div class="d-flex flex-column col-6 p-0 ps-2">
            <label class="label"><nb-icon icon="robot" size="small"></nb-icon> AI
              Summary/Description</label>
            <p class="mt-1 mb-0 title text-align-justify"><small>{{item.summary}}</small></p>
          </div>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <div class="d-flex">
          <label class="label">Published On {{item.publishedDate|date:'dd-MMM-yy hh:mm:ss a'}}</label>
        </div>
      </nb-card-footer>
    </nb-card>
  </nb-list-item>
</nb-list>
