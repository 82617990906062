import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, RouterModule } from "@angular/router";
import { routes } from "./app.routes";
import { ReactiveFormsModule } from "@angular/forms";
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RestService } from "./services/rest.service";
import { HttpClientModule } from "@angular/common/http";
import { DhanFeedService } from "./services/dhan.socket.service";
import { CoreModule } from "./core/core.module";
import { NbAccordionModule, NbButtonModule, NbCardModule, NbDatepickerModule, NbDialogModule, NbFormFieldModule, NbGlobalPhysicalPosition, NbIconModule, NbInputModule, NbLayoutModule, NbListModule, NbMenuModule, NbPopoverModule, NbSelectModule, NbSidebarModule, NbTabsetModule, NbThemeModule, NbToastrModule, NbToggleModule, NbTooltipModule } from "@nebular/theme";
import { AppRoutingModule } from "./app-routing.module";
import { DARK_THEME } from './core/styles/theme.dark'
import { CORPORATE_THEME } from "./core/styles/theme.corporate";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { StrategyCallDetailsComponent } from "./strategy-call-details/strategy-call-details.component";
import { AgGridModule } from "@ag-grid-community/angular";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { AddEditStrategyCallComponent } from "./add-edit-strategy-call/add-edit-strategy-call.component";
import { NewsComponent } from "./news/news.component";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    StrategyCallDetailsComponent,
    AddEditStrategyCallComponent,
    NewsComponent
  ],
  providers: [provideRouter(routes), RestService, DhanFeedService],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CoreModule,
    NbLayoutModule,
    NbSidebarModule,
    NbMenuModule,
    NbIconModule,
    NbInputModule,
    NbFormFieldModule,
    NbDatepickerModule.forRoot(),
    NbSelectModule,
    NbToggleModule,
    NbTooltipModule,
    NbListModule,
    NbCardModule,
    NbButtonModule,
    NbAccordionModule,
    NbTooltipModule,
    NbPopoverModule,
    NbTabsetModule,
    NbToggleModule,
    AgGridModule,
    NbDialogModule.forRoot({ autoFocus: true, closeOnBackdropClick: false }),
    NbToastrModule.forRoot({ destroyByClick: true, duration: 5000, position: NbGlobalPhysicalPosition.TOP_RIGHT }),
    AppRoutingModule,
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    HttpClientModule,
    NbThemeModule.forRoot({
      name: 'dark'
    }, [CORPORATE_THEME, DARK_THEME])
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
