import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteConstants } from './constants/constant';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './services/auth.guard';
import { StrategyCallDetailsComponent } from './strategy-call-details/strategy-call-details.component';
import { NewsComponent } from './news/news.component';

const routes: Routes = [
  { path: '', redirectTo: 'ai-articles', pathMatch: 'full' },

  {
    path: RouteConstants.DASHBOARD_ROUTE, component: DashboardComponent, canActivate: [AuthGuard]
  },
  {
    path: 'ai-articles', component: NewsComponent
  },
  {
    path: RouteConstants.STRATEGY_ROUTE + '/:strategyId', component: StrategyCallDetailsComponent, canActivate: [AuthGuard]
  },

  { path: RouteConstants.AUTH_MAIN_ROUTE, loadChildren: () => import('./auth/auth.module').then((module) => module.AuthModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
