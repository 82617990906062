import { Component, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { RestService } from '../services/rest.service';
import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged } from 'rxjs';
import { DateTime } from 'luxon';
import { ColDef } from '@ag-grid-community/core'
import { NbDialogRef, NbDialogService, NbPopoverDirective, NbToastrService } from '@nebular/theme';
import { AddEditStrategyCallComponent } from '../add-edit-strategy-call/add-edit-strategy-call.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-strategy-call-details',
  templateUrl: './strategy-call-details.component.html',
  styleUrl: './strategy-call-details.component.scss'
})
export class StrategyCallDetailsComponent {
  private strategyId: string;
  calls: any[];
  strategyDetails: any;
  orderEntryColumnDefs: ColDef[];
  selectedCall: any;
  filtersForm: FormGroup;
  isLoading: boolean;
  @ViewChild(NbPopoverDirective) popover!: NbPopoverDirective;
  @ViewChild('restartConfirmation') restartConfirmation!: TemplateRef<any>;
  @ViewChild('exitConfirmation') exitConfirmation!: TemplateRef<any>;
  @ViewChild('deleteConfirmation') deleteConfirmation!: TemplateRef<any>;
  @ViewChild('infoDialog') infoDialog!: TemplateRef<any>;
  @ViewChild(NbPopoverDirective) filtersPopover!: NbPopoverDirective;
  @ViewChildren('multiSelect') multiselects: any;
  infoDialogRef?: NbDialogRef<any>;
  filters: any[] = [];
  constructor(private formBuilder: FormBuilder, private rest: RestService, private activatedRoute: ActivatedRoute, private toastr: NbToastrService, private dialogService: NbDialogService) {
    this.strategyId = '';
    this.orderEntryColumnDefs = [];
    this.filtersForm = this.formBuilder.group({});
    this.calls = [];
    this.isLoading = false;
    this.initializeFilters();
    this.fetchBrokerAccounts();
    this.initializeColDefs();
    this.activatedRoute.params.pipe(distinctUntilChanged()).subscribe((params) => {
      this.strategyId = params['strategyId'];
      if (this.strategyId) {
        this.fetchStrategyDetails();
        this.applyFilters();
      }
    });
  }

  private fetchStrategyDetails() {
    this.rest.fetchStrategyById(this.strategyId).subscribe((response) => {
      this.strategyDetails = response.data;
      this.filters[1].source = this.strategyDetails.metadata
        .find((metadata: any) => metadata.key === 'indice')?.source.options;
      this.filtersForm.patchValue({ indice: this.filters[1].source.map((option: any) => option.value) });
    })
  }

  openFiltersSection() {
    this.filtersPopover.show();
  }

  closeFiltersSection() {
    this.filtersPopover?.hide();
  }

  private initializeFilters() {
    this.filters = [
      { key: 'accountId', label: 'Account', type: 'multiselect', source: [] },
      { key: 'indice', label: 'Indices', type: 'multiselect', source: [] },
      {
        key: 'runningDateRange', label: 'Running Date', type: 'daterange', config: {
          min: DateTime.now().minus({ day: 5 }).startOf('day').toJSDate(),
          max: DateTime.now().plus({ day: 5 }).endOf('day').toJSDate()
        }
      },
      {
        key: 'createdDateRange', label: 'Created On', type: 'daterange', config:
        {
          min: DateTime.now().minus({ day: 5 }).startOf('day').toJSDate(),
          max: DateTime.now().endOf('day').toJSDate()
        }
      }
    ];
    this.filters.forEach((filter) => {
      if (filter.key === 'runningDateRange') {
        const value = {
          start: DateTime.now().startOf('day').toJSDate(),
          end: DateTime.now().plus({ day: 5 }).endOf('day').toJSDate()
        };
        const control = new FormControl({ disabled: false, value });
        this.filtersForm.addControl(filter.key, control);
      } else if (filter.key === 'createdDateRange') {
        const value = {
          start: DateTime.now().minus({ day: 3 }).startOf('day').toJSDate(),
          end: DateTime.now().endOf('day').toJSDate()
        };
        const control = new FormControl({ disabled: false, value });
        this.filtersForm.addControl(filter.key, control);
      } else {
        this.filtersForm.addControl(filter.key, new FormControl());
      }
    });
  }

  applyFilters() {
    const filtersValue = this.filtersForm.value;
    const payload = {
      startRow: 0,
      endRow: 100,
      strategyId: [this.strategyId],
      includeOrders: true,
      accountId: filtersValue.accountId,
      status: ['Pending', 'Active', 'Completed', 'Expired', 'AuthFailed', 'OrderPlaced'],
      runningDateRange: [
        DateTime.fromJSDate(filtersValue.runningDateRange.start).startOf('day').toJSDate(),
        DateTime.fromJSDate(filtersValue.runningDateRange.end).endOf('day').toJSDate()
      ],
      createdDateRange: [
        DateTime.fromJSDate(filtersValue.createdDateRange.start).startOf('day').toJSDate(),
        DateTime.fromJSDate(filtersValue.createdDateRange.end).endOf('day').toJSDate()
      ],
      order: [
        ['runningStatus', 'DESC'],
        ['updatedAt', 'DESC']
      ]
    };
    this.fetchCalls(payload);
    this.closeFiltersSection();
  }


  private fetchBrokerAccounts() {
    const payload = {
      startRow: 0,
      endRow: 10,
      status: 'Active',
      order: [
        ['brokerIdentifier', 'ASC']
      ]
    }
    this.rest.getAccounts(payload).subscribe((response) => {
      this.filters[0].source = ((response.data?.rows || []))
        .map((account: any) => ({ label: `${account.clientId}(${account.broker})`, value: account.accountId }));

      this.filtersForm.patchValue({ accountId: this.filters[0].source.map((option: any) => option.value) });
    })
  }

  getMultiselectLabel(control: string) {
    const filter = this.filters.find((filter) => filter.key === control);
    const filterControl = this.filtersForm.get(control);
    let label = `Select ${filter.label}`;
    if (filterControl?.value?.length) {
      label = filter.source.length == filterControl?.value?.length ? 'All' : `${filterControl?.value?.length} Selected`;
    }
    return label;
  }

  private fetchCalls(payload: any) {
    this.isLoading = true;
    this.rest.fetchStrategyCalls(payload).subscribe((response) => {
      this.calls = response.data?.rows || [];
      this.calls.forEach((call) => {
        call.title = `${call.scripConfig.sym} ${DateTime.fromFormat(call.scripConfig.exp, 'yyyy-MM-dd').toFormat('dd MMM yyyy').toUpperCase()}`
        const tradeCycles: any = { Buy: 0, Sell: 0, BuyError: 0, SellError: 0 };
        (call.orders || []).forEach((order: any) => {
          if (order.orderStatus === 'Traded') {
            tradeCycles[order.tradeSide] += 1;
          } else {
            tradeCycles[`${order.tradeSide}Error`] += 1;
          }
        })
        call.buyCount = tradeCycles.Buy;
        call.sellCount = tradeCycles.Sell;
        call.iterationsPending = Math.abs(tradeCycles.Buy - tradeCycles.Sell);
      })
    }).add(() => {
      this.isLoading = false;
    })
  }

  showCallInfo(call: any) {
    const dataList: any[] = [{ label: 'Account', value: `${call.clientId} (${call.brokerCode})` }];
    this.strategyDetails.metadata.forEach((metadata: any) => {
      let value = metadata.validations.unit == '%' ? Number(call.details[metadata.key]) * 100 : metadata.type == 'toggle' ? call.details[metadata.key] ? 'Yes' : 'No' : call.details[metadata.key];
      if (metadata.source?.options?.length) {
        value = (metadata.source?.options.find((item: any) => item.value === value))?.label;
      } if (metadata.type === 'datepicker') {
        value = DateTime.fromFormat(value, 'yyyy-MM-dd').toFormat('dd MMM yyyy');
      }
      dataList.push({ label: metadata.label, value: value, unit: metadata.validations.unit })
    });
    this.infoDialogRef = this.dialogService.open(this.infoDialog, { context: dataList });
  }

  private initializeColDefs() {
    this.orderEntryColumnDefs = [
      {
        field: 'orderNumber',
        headerName: 'Order Number',
        pinned: 'left',
        maxWidth: 130,
        tooltipField: 'orderNumber'
      },
      {
        field: 'scripName',
        headerName: 'Scrip',
        pinned: 'left',
        tooltipField: 'scripName'
      },
      {
        field: 'tradeSide',
        headerName: 'Buy/Sell',
        cellClass: (params) => params.value == 'Sell' ? 'text-danger' : 'text-success',
        maxWidth: 80,
        tooltipField: 'tradeSide'
      },
      {
        field: 'quantity',
        headerName: 'Quantity Filled',
        valueFormatter: (params) => `${params.data.tradedQuantity || 0}/${params.value}`,
        maxWidth: 100,
        tooltipValueGetter: (params) => `${params.data.tradedQuantity || 0}/${params.value}`,
      },
      {
        field: 'productType',
        headerName: 'Order Detail',
        valueFormatter: (params) => `${params.value} | ${params.data.validity} | ${params.data.orderType}`,
        maxWidth: 160,
        tooltipValueGetter: (params) => `${params.value} | ${params.data.validity} | ${params.data.orderType}`,
      },
      {
        field: 'remarks',
        headerName: 'Remarks',
        maxWidth: 180,
        tooltipField: 'remarks'
      },
      {
        field: 'createdAt',
        headerName: 'Placed At',
        pinned: 'right',
        valueFormatter: (params) => params.value ? DateTime.fromISO(params.value).toFormat('dd MMM hh:mm:ss a') : '',
        maxWidth: 150,
        tooltipValueGetter: (params) => params.value ? DateTime.fromISO(params.value).toFormat('dd MMM hh:mm:ss a') : '',
      },
      {
        field: 'orderStatus',
        headerName: 'Status',
        pinned: 'right',
        cellClass: (params) => ['Partial', 'Traded'].includes(params.value) ? 'text-success' : ['Cancelled', 'Transit'].includes(params.value) ? 'text-info' : 'text-danger',
        maxWidth: 100,
        tooltipField: 'orderStatus'
      },
      {
        field: 'error',
        headerName: 'Message',
        minWidth: 500,
        tooltipField: 'error'
      }
    ]
  }

  callForOperation(call: any) {
    this.selectedCall = call;
  }

  public deleteCall() {
    if (this.selectedCall) {
      this.rest.deleteCall(this.selectedCall.callId).subscribe((response) => {
        if (response.data) {
          this.toastr.success('Call Deleted successfully', 'Call Deletion Operation');
          this.calls = this.calls.filter((call) => call.callId !== this.selectedCall.callId);
          this.selectedCall = null;
        } else {
          this.toastr.danger(response.message || `Call could not be deleted`, 'Call Deletion Operation');
        }
      })
    }
  }

  updateRunningStatus(call: any, status: string) {
    this.rest.updateCallRun({ callId: call.callId, runningStatus: status }).subscribe((response) => {
      if (response.data) {
        this.toastr.success(`Call Running status updated to ${response.data.runningStatus}`, 'Call Running status Updation Request');
        const call = this.calls.find((call) => call.callId == call.callId)
        call.runningStatus = response.data.runningStatus;
        call.status = response.data.status;
      } else {
        this.toastr.danger(response.message || `Call could not be updated`, 'Call Running status Updation Request');
      }
    })
  }

  openCreateDialog(call: any, isEdit?: true) {
    const dialogRef = this.dialogService.open(AddEditStrategyCallComponent, {
      context: {
        strategyDetails: this.strategyDetails,
        call: call, isEdit: isEdit
      }
    });
    dialogRef.onClose.subscribe((response) => {
      if (response && response.callId) {
        if (isEdit) {
          let callIndex = this.calls.findIndex((call) => call.callId == response.callId);
          this.calls[callIndex] = response;
        } else {
          this.applyFilters();
        }
      }
    });
  }

  cloneCall(call: any) {
    const dialogRef = this.dialogService.open(AddEditStrategyCallComponent, {
      context: {
        strategyDetails: this.strategyDetails,
        call: call, isEdit: false
      }
    });
    dialogRef.onClose.subscribe((response) => {
      if (response && response.callId) {
        this.applyFilters();
      }
    });
  }
}
