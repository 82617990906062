import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Created with ♥ by <b><a href="#" target="_blank">Anonymous</a></b> {{currentYear}}
    </span>
    <div class="socials">
      <a href="#" target="_blank" class="ion ion-social-github"><nb-icon icon="brand-github" pack="tabler-icons"></nb-icon></a>
      <a href="#" target="_blank" class="ion ion-social-facebook"><nb-icon icon="brand-facebook" pack="tabler-icons"></nb-icon></a>
      <a href="#" target="_blank" class="ion ion-social-twitter"><nb-icon icon="brand-twitter" pack="tabler-icons"></nb-icon></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"><nb-icon icon="brand-linkedin" pack="tabler-icons"></nb-icon></a>
    </div>
  `,
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
}
